import React, { useState } from 'react';
import './services.css';
// import card1 from '../img/card1.jpg'
// import card2 from '../img/card2.jpg'
// import card3 from '../img/card3.jpg'
import card1 from '../img/LED Wall 1.jpeg'
import card2 from '../img/Corporate Evetns.jpg'
import card3 from '../img/Conferences & Seminars.jpg'
import card4 from '../img/Sales Events.jpeg'
import card5 from '../img/National Events.jpg'
import card6 from '../img/Job Fiar.jpeg'
import card7 from '../img/Convocations.jpg'
import card8 from '../img/Concerts.jpeg'
import card9 from '../img/Asim Azhar for TFS.jpeg'
import card10 from '../img/BTL TTL.jpeg'
import card11 from '../img/Stall Fabrication.jpg'
import card12 from '../img/University Events.jpeg'

import cvideo from '../img/Concerts.mp4'
import cvideo1 from '../img/SHU Convocation.mp4'
import cvideo2 from '../img/Corporate Events.mp4'
import cvideo3 from '../img/Corporate Events.mp4'
import cvideo4 from '../img/stall.mp4'
import cvideo5 from '../img/stall.mp4'
import cvideo6 from '../img/Job Fair.mp4'
import cvideo7 from '../img/LED Wall Video.mp4'
import cvideo8 from '../img/ac.mp4'
import cvideo9 from '../img/Sales Event.mp4'






import Modal from 'react-modal';

function Card(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ x: null, y: null });
  const [modalVideoSource, setModalVideoSource] = useState('');


  function openModal(event, videoSource) {
    setModalPosition({ x: event.clientX, y: event.clientY });
    setModalIsOpen(true);
    setModalVideoSource(videoSource); // Add this line to set the video source in the state
  }
  

  return (
    <div className="container-service">
      <h1 className="umyservicesheading">OUR SERVICES</h1>
      <div className="container">
        <div className="row">
          <div className="ucontainer">


            <div className="ucard" style={{ '--clr': '#FFC40A' }}>
              <div className="uimg-box">
                <img src={card1} alt="Desert" />
              </div>
              <div className="ucontent">
                <h2 className='utext'>LED Wall/ SMD Screens</h2>
                <p>
                Discover unparalleled quality and reliability in SMD screens through our 
                industry-leading supply expertise. SMD Screens on rent for your Events 
                with professional operators and industry renowned Softwares.
                </p>
                <a onClick={(event) => openModal(event, cvideo7)}>Read More</a> {/* Pass cvideo2 as the video source */}

              </div>
            </div>


            <div className="ucard" style={{ '--clr': '#87CEEB' }}>
              <div className="uimg-box">
                <img src={card2} alt="Mountains" />
              </div>
              <div className="ucontent">
                <h2>Corporate Events</h2>
                <p>
                Elevate your corporate gatherings with our seamless event 
                planning and management services. 360 Event Services.
                </p>
                <a onClick={(event) => openModal(event, cvideo2)}>Read More</a> {/* Pass cvideo2 as the video source */}

              </div>
            </div>


            <div className="ucard" style={{ '--clr': '#FFC40A' }}>
              <div className="uimg-box">
                <img src={card3} alt="Ocean" />
              </div>
              <div className="ucontent">
                <h2>Seminars & Conferences</h2>
                <p>
                Elevate your next conference or seminar with our seasoned event planning team, 
                ensuring a memorable experience for attendees and speakers alike. Complete 
                audio/visual support.                </p>
                <a onClick={(event) => openModal(event)}>Read More</a>
              </div>
            </div>

            <div className="ucard" style={{ '--clr': '#FFC40A' }}>
              <div className="uimg-box">
                <img src={card4} alt="Mountains" />
              </div>
              <div className="ucontent">
                <h2>Sales Events</h2>
                <p>
                Property Sales Events for Real Estates & Others with Sales
                 Settings and Customer engaging activities
                </p>
                <a onClick={(event) => openModal(event, cvideo9)}>Read More</a> {/* Pass cvideo2 as the video source */}

              </div>
            </div>

            <div className="ucard" style={{ '--clr': '#87CEEB' }}>
              <div className="uimg-box">
                <img src={card5} alt="Mountains" />
              </div>
              <div className="ucontent">
                <h2>National Events</h2>
                <p>
                Having experience of State Level events featuring the President, Prime Minister and Chief of 
                Army Staff, The Festive Solutions has expertise in SOP Compliance and Logistical Support.
                </p>
                <a onClick={(event) => openModal(event)}>Read More</a>
              </div>
            </div>

            <div className="ucard" style={{ '--clr': '#FFC40A' }}>
              <div className="uimg-box">
                <img src={card6} alt="Mountains" />
              </div>
              <div className="ucontent">
                <h2>Job Fairs</h2>
                <p>
                Having expertise to set up stalls and branding for different companies connecting Employers & Employees. 
                We have grip over managing Stall bearers as per their sponsorship packages. 
                </p>
                <a onClick={(event) => openModal(event, cvideo6)}>Read More</a> {/* Pass cvideo2 as the video source */}

              </div>
            </div>

            <div className="ucard" style={{ '--clr': '#FFC40A' }}>
              <div className="uimg-box">
                <img src={card7} alt="Mountains" />
              </div>
              <div className="ucontent">
                <h2>Convocations</h2>
                <p>
                Make your convocation a momentous occasion with our expert event coordination 
                and personalized touches for once-in-your-lifetime event. 
                </p>
                <a onClick={(event) => openModal(event, cvideo1)}>Read More</a> {/* Pass cvideo2 as the video source */}

              </div>
            </div>

            <div className="ucard" style={{ '--clr': '#87CEEB' }}>
              <div className="uimg-box">
                <img src={card8} alt="Mountains" />
              </div>
              <div className="ucontent">
                <h2>Concerts</h2>
                <p>
                Experience the magic of live music like never before with our curated concert experiences. 
                Equipment as per Artist compliance as well as complete visual and sound support.
                </p>
                <a onClick={(event) => openModal(event, cvideo)}>Read More</a> {/* Pass cvideo2 as the video source */}

              </div>
            </div>

            <div className="ucard" style={{ '--clr': '#FFC40A' }}>
              <div className="uimg-box">
                <img src={card9} alt="Mountains" />
              </div>
              <div className="ucontent">
                <h2>Artists Management</h2>
                <p>
                Having booked artists like Asim Azhar, Mustafa Zahid, Abu Fareed Qawwal, Young Stunners 
                and others, we have the long list of artist suited for your events. 
                </p>
                <a onClick={(event) => openModal(event, cvideo8)}>Read More</a> {/* Pass cvideo2 as the video source */}

              </div>
            </div>

            <div className="ucard" style={{ '--clr': '#FFC40A' }}>
              <div className="uimg-box">
                <img src={card10} alt="Mountains" />
              </div>
              <div className="ucontent">
                <h2>ATLs/BTLs</h2>
                <p>
                Amplify your brand's reach with our ATL (Above-the-Line) strategies, reaching a 
                wide audience through traditional advertising. Boost engagement and create personalized 
                connections with our BTL (Below-the-Line) marketing tactics, tailored for a targeted impact. 
                </p>
                <a onClick={(event) => openModal(event, cvideo4)}>Read More</a> {/* Pass cvideo2 as the video source */}
              </div>
            </div>

            <div className="ucard" style={{ '--clr': '#FFC40A' }}>
              <div className="uimg-box">
                <img src={card11} alt="Mountains" />
              </div>
              <div className="ucontent">
                <h2>Fabrications</h2>
                <p>
                Specifically designed Stalls for your exhibitions, 3D letters, Tunnel Entries
                 and much more. 

                </p>
                <a onClick={(event) => openModal(event)}>Read More</a>
              </div>
            </div>

            <div className="ucard" style={{ '--clr': '#FFC40A' }}>
              <div className="uimg-box">
                <img src={card12} alt="Mountains" />
              </div>
              <div className="ucontent">
                <h2>University Events</h2>
                <p>
                From Welcome Parties, Farewell, Beach Parties and Award Ceremonies, we have had the 
                experience to manage year-long events for various academic institutions
                </p>
                <a onClick={(event) => openModal(event)}>Read More</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
  isOpen={modalIsOpen}
  onRequestClose={() => setModalIsOpen(false)}
  style={{
    content: {
      top: modalPosition.y ? modalPosition.y + 'px' : '50%',
      left: modalPosition.x ? modalPosition.x + 'px' : '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(0%, -144%)',
    },
  }}
  contentLabel="Video Modal"
>
  <button className="modal-close-button btn-service" onClick={() => setModalIsOpen(false)}>
    <i className="fas fa-times"></i>
  </button>
  <div>
    {/* Use the video source from state */}
    <video className='cardvideo'
      
      width="560"
      height="315"
      controls // Add controls for video playback
    >
      <source src={modalVideoSource} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</Modal>


    </div>
  );
}

export default Card;
