import React from 'react';
import './partners.css'; // Import the CSS file for the component

import instagramLogo from '../img/instagram-logo.png';


import GoB from '../img/GoB.png';
import govt from '../img/govt.png';
import SevenUp from '../img/7Up.png';
import ACP from '../img/ACP.png';
import zameenLogo from '../img/ZAMEEN.png';
import Defclarea from '../img/Defclarea.png';
import FAST from '../img/FAST.png';
import Fedex from '../img/Fedex.png';
import Foodpanda from '../img/Foodpanda.png';
import Gerrys from '../img/gerrys.png';
import Habib  from '../img/HabibUniversity.png';
import Honda  from '../img/HondaAtlas.png';
import HumTv  from '../img/HumTv.png';
import IBA  from '../img/IBA.png';
import MG  from '../img/MG.png';
import PIA  from '../img/PIA.png';
import PIS  from '../img/PIS.png';
import PTCL  from '../img/PTCL.png';
import SHU  from '../img/SHU.png';
import Suzuki  from '../img/SUZUKI.png';
import TMUC  from '../img/TMUC.png';
import Ufone  from '../img/UFONE.png';



import { useEffect } from 'react';
import { fas } from '@fortawesome/free-solid-svg-icons';
const logos = [GoB,govt,SevenUp,ACP,zameenLogo,Defclarea,FAST,Fedex,Foodpanda,Gerrys,Habib,Honda,HumTv,IBA,MG,PIA,PIS,PTCL,SHU,Suzuki,TMUC,Ufone];

const Partners = () => {

  useEffect(() => {
    const copy = document.querySelector('.logos-slide').cloneNode(true);
    document.querySelector('.logos').appendChild(copy);
  }, []);

  return (
  <>
  <h1 className="vmyservicesheading">OUR CLIENTS</h1>
    <div className="logos">
    <div className="logos-slide">
    
      <img src={GoB} alt="3M" />
      <img src={govt} alt="Barstool Store" />
      <img src={SevenUp} alt="Budweiser" />
      <img src={ACP} alt="Buzzfeed" />
      <img src={zameenLogo} alt="Forbes" />
      <img src={Defclarea} alt="Macy's" />
      <img src={FAST} alt="Men's Health" />
      <img src={Fedex} alt="MrBeast" />
      <img src={Foodpanda} alt="MrBeast" />
      <img src={Gerrys} alt="MrBeast" />
      <img src={Habib} alt="MrBeast" />
      <img src={Honda} alt="MrBeast" />
      <img src={HumTv} alt="MrBeast" />
      <img src={IBA} alt="MrBeast" />
      <img src={MG} alt="MrBeast" />
      <img src={PIA} alt="MrBeast" />
      <img src={PIS} alt="MrBeast" />
      <img src={PTCL} alt="MrBeast" />
      <img src={SHU} alt="MrBeast" />
      <img src={Suzuki} alt="MrBeast" />
      <img src={TMUC} alt="MrBeast" />
      <img src={Ufone} alt="MrBeast" />

    </div>
  </div>
  </>
  );
};

export default Partners;