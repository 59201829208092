import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import myimage from '../img/festive2.png'
import './About.css'
import img1 from '../img/fess2.jpeg'

function About() {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isBarOpened, setIsBarOpened] = useState(false);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = () => {
    if (isBarOpened) {
      setIsBarOpened(false);
    }
  };

  return (
  
    <div className="container-about">
      <section className="about-section">
      
        <div className="container">
          <div className="row">
            {/* Image Column */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12 order-lg-2">
              <div className="inner-column wow fadeInRight">
                <div className="author-desc">
                  <h2>THE FESTIVE SOLUTIONS</h2>
                  <span>IDEAS DELIVERED</span>
                </div>
                <figure className="image-1">
                  <a href="#" className="lightbox-image" data-fancybox="images">
                    <img title="THE FESTIVE SOLUTIONS" src={img1} alt="" />
                  </a>
                </figure>
              </div>
            </div>
        


            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-lg-1">
              <div className="inner-column">
                <div className="sec-title">
                  <h2>ABOUT US</h2>
                </div>
                <div className="text">
                The Festive Solutions is a team of robust and dedicated youngsters working to create & strengthen the presence of local & 
                international brands at every forum while building strategies that are successful & known to be used worldwide. Being in the 
                industry for 6 years, we have set new standards. 
                </div>
                <div className="text">
                The Festive Solutions will support you in whatever scale or scope of event project that you are working on. From an initial 
                scale business conference, right through to a brand launch, team The Festive Solutions has the relevant skills, expertise and 
                experience to manage and execute your event in any capacity that you require.
                As our motto say:
                Ideas delivered. WE COMMIT TO IT
                </div>
                <div className="btn-box">

                  <div class="bcontainer">
                    <Link
                    activeClass="active"
                    to="footer-icons"
                    spy={true}
                    smooth={true}
                    duration={1000}
                    className="ubtn btn1"
                    onClick={handleLinkClick}
                    offset={-200}
                  >
                    Contact Us
                  </Link>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;