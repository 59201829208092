import React from 'react';
import './OurEvents.css';
import { useEffect, useState } from 'react';
import Footer from './Footer'
import { Link } from 'react-router-dom';
import Services1 from '../img/Pak-Iran Border Inauguration.jpg';
import Services2 from '../img/Awran Cultural Festival.jpg';
import Services3 from '../img/Pakistan Motor Rally 2017 Thumbnaik.jpg';
import Services4 from '../img/IMG_8278.jpeg';
import Services5 from '../img/VELO.jpg';
import Services6 from '../img/IMG_5612.jpeg';
import Services7 from '../img/PTCL.jpg';
import Services8 from '../img/SHU.jpeg';
import Services9 from '../img/ZAMEEN.jpeg';
import Services10 from '../img/DSL 2022 (1).jpg';
import Services11 from '../img/PAKIRAN.jpg';
import Services12 from '../img/TPLEX.jpg';
import Services13 from '../img/IAP.jpg';
import Services14 from '../img/Jhakvani Villas Ground Breaking.jpg';
import Services15 from '../img/Intimo Wax Launch.jpg';
import Services16 from '../img/Abdullah Estate Naya Nazimabad Launch.jpg';
import Services17 from '../img/MITE.jpg';
import Services18 from '../img/PROCOM.jpeg';
import myvideo1 from '../img/SHU Convocation.mp4';
import festivelogo from '../img/festive.png';

function OurEvents() {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    // Set the fade-in effect immediately on the first load
    setFadeIn(true);

    // Function to toggle the fade-in effect
    const toggleFade = () => {
      setFadeIn((prevValue) => !prevValue);
    };

    // Set an interval to toggle the fade every 4 seconds
    const interval = setInterval(toggleFade, 4000);

    // Clear the interval when the component is unmounted or the dependency changes
    return () => clearInterval(interval);
  }, []);


  return (
    <>
    <header>

    <img src={festivelogo} alt="Festive Logo" className="festive-logo" />
      <video src={myvideo1} loop autoPlay muted></video>
      <h1 className={`fade-in ${fadeIn ? 'active' : ''} heading`}>
        <span className="welcome"> Our </span> Past Events
        
      </h1>
      
      <div className="headerbg"></div>
    </header>

 

    <div className="container-flip">
    <div className="row">
      
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img style={{ objectFit: 'none' }} src={Services1} alt="Card Front Image" />
            <div className="card-title1">Pak-Iran Border Inauguration</div>
          </div>
          
          <div className="flip-card-back">
            <div className="card-content">
              <h2>Pak-Iran Border Inauguration</h2>
              <p>
              Featuring the Prime Minister of Pakistan and the President of Iran, a Border Market & Power Plant was inaugurated at Mand-Pishin Border near Turbat. 
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    

      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services2} alt="Card Front Image" />
            <div className="card-title1">Awaran Cultural Festival</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>Awaran Cultural Festival</h2>
              <p>
              Featuring cultural artists, The Festive Solutions managed all the logistic challenges in the remote area for the Pak Army organized event. 
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>


      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services3} alt="Card Front Image" />
            <div className="card-title1">Pakistan Motor Rally 2017</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>Pakistan Motor Rally 2017</h2>
              <p>
              Held in Gwadar in 2017, The Festive Solutions covered Pakistan Motor Rally for Pak Suzuki featuring the serving COAS and artists including Ali Zafar, Atif Aslam and many more. 
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>


      </div>
      </div>

     

      <div className="container-flip">
    <div className="row">
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services4} alt="Card Front Image" />
            <div className="card-title1">Shahid Afridi Name Unveiling Event</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>Shahid Afridi Name Unveiling Event</h2>
              <p>
              Featuring the President of Pakistan & the renowned Cricketer Shahid Afridi, The Festive Solutions designed & executed the Name Unveiling Ceremony of Salim Habib University.

              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services5} alt="Card Front Image" />
            <div className="card-title1">VELO Top Traders Event</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>VELO Top Traders Event</h2>
              <p>
              360 Event Services for VELO Top Traders and the entertainment session Abu Fareed Qawwal at PC Hotel, Karachi. 
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>


      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services6} alt="Card Front Image" />
            <div className="card-title1">Dev Day 2023</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>Dev Day 2023</h2>
              <p>
              With more than 30 IT Brands participating, TFS provided Stall Fabrication, Branding and Complete Marquee Services. 
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>


      </div>
      </div>





    <div className="container-flip">
    <div className="row">
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services7} alt="Card Front Image" />
            <div className="card-title1">PTCL Contract Signing Ceremony</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>PTCL Contract Signing Ceremony</h2>
              <p>
              The new fibre link being deployed MoU was signed by PTCL at Governor House, Karachi

              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services8} alt="Card Front Image" />
            <div className="card-title1">SHU Convocation 2023</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>SHU Convocation 2023</h2>
              <p>
              The first Convocation of Salim Habib University was designed and planned by TFS featuring the renowned cricketer Shahid Afridi. 
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>


      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services9} alt="Card Front Image" />
            <div className="card-title1">Zameen.com Property Sales Event</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>Zameen.com Property Sales Event</h2>
              <p>
              Property Sales Event
              Complete Property Sales Event for Zameen. com at Roomi Icon Karachi
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      </div>
      </div>

      <div className="container-flip">
    <div className="row">
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services10} alt="Card Front Image" />
            <div className="card-title1">DSL 2022</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>DSL 2022</h2>
              <p>
              Organized by Defclarea, the largest realtors association of Pakistan, the Cricket league with the opening and closing ceremony was organized at Moin Khan Cricket Academy. 
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services11} alt="Card Front Image" />
            <div className="card-title1">Pak-Iran Border Opening</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>Pak-Iran Border Opening</h2>
              <p>
              The border market and crossing at Mand-Pishin was organized by FC Pakistan featuring the foreign Ministers of both countries: Pakistan & Iran. 
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>


      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services12} alt="Card Front Image" />
            <div className="card-title1">TPLEX Awards Night</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>TPLEX Awards Night</h2>
              <p>
              To appreciate the employees of emerging IT Company, an awards night and entertainment session was organized at a farmhouse. 
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      </div>
      </div>
      
      <div className="container-flip">
    <div className="row">
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services13} alt="Card Front Image" />
            <div className="card-title1">IAP Fundraisers</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>IAP Fundraisers</h2>
              <p>
              To raise funds for Flood victims, Fund Raising event was held at Marriott Hotel, Karachi
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services14} alt="Card Front Image" />
            <div className="card-title1">Jakhvani Villas Ground Breaking</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>Jakhvani Villas Ground Breaking</h2>
              <p>
              Held at DHA City, a complete Marquee and set-up was organized in the remote area of DHA City, Karachi
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>


      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services15} alt="Card Front Image" />
            <div className="card-title1">Intimo Wax Launch</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>Intimo Wax Launch</h2>
              <p>
              For the launch of a Wax Brand based in Lahore, the wax launch event was held at Karachi Gymkhana, Karachi.
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      </div>
      </div>

      <div className="container-flip">
    <div className="row">
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services16} alt="Card Front Image" />
            <div className="card-title1">Abdullah Estate Launch</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>Abdullah Estate Launch</h2>
              <p>
              Held at Naya Nazimabad, the Real Estate Branch was launched. 
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services17} alt="Card Front Image" />
            <div className="card-title1">MITE Convocation</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>MITE Convocation</h2>
              <p>
              For the graduating students, the Convocation setup was done for MITE University, Karachi.
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>


      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={Services18} alt="Card Front Image" />
            <div className="card-title1">FAST PROCOM 23</div>
          </div>
          <div className="flip-card-back">
            <div className="card-content">
              <h2>FAST PROCOM 23</h2>
              <p>
              The job fair at FAST was held in 2023 featuring various IT Companies.
              </p>
              <a href="#" className="btn">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      </div>
      </div>

    </>
    
  );
  
}
export default OurEvents;