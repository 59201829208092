import { Swiper, SwiperSlide } from "swiper/react";
import "./PastEvent.css";

import "swiper/css";
import { useNavigate } from 'react-router';


import image1 from "../img/Pak-Iran Border Inauguration.jpg";
import image2 from "../img/VELO.jpg";
import image3 from "../img/SHU.jpeg";
import image4 from "../img/IMG_5612.jpeg";
import image5 from "../img/IMG_8278.jpeg";
import image6 from "../img/Asim Azhar for TFS.jpeg";




const slides = [image1, image2, image3, image4, image5, image6];

const Carousel = () => {
  const navigate = useNavigate();
  const handleSeePortfolio = () => {
    window.scrollTo(0, 0);
    navigate('/our-event');
  };


  return (
    <div className="container-slide">
      <h1 className="servicesheading">PAST EVENTS</h1>
      <Swiper spaceBetween={10} slidesPerView={3} loop>
        {slides.map((slide) => (
          <SwiperSlide>
            <img className="image-slide" src={slide}  alt="NoImgFound"/>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="inner-column wow fadeInRight">
        <div className='button-container'>

          <button class="ubtn btn1" onClick={handleSeePortfolio}>Our Portfolio</button>
        </div>
      </div>
    </div>
  );
};
export default Carousel;